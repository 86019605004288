import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "ToDo",
  "author": "Stefan Huber",
  "date": "2020-01-17",
  "layout": "post",
  "draft": false,
  "hideInMenu": false,
  "path": "/todo/",
  "tags": ["TagOne", "TagTwo"],
  "description": ""
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "to-check"
    }}>{`To check`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "http://leonidas.org/about/digital-cresci/"
          }}>{`http://leonidas.org/about/digital-cresci/`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://github.com/loicsander/RobofabPens"
          }}>{`https://github.com/loicsander/RobofabPens`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "http://ceur-ws.org/Vol-1767/c3gi2016_paper2.pdf"
          }}>{`http://ceur-ws.org/Vol-1767/c3gi2016_paper2.pdf`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://twitter.com/justvanrossum/status/755236901364305923"
          }}>{`https://twitter.com/justvanrossum/status/755236901364305923`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "http://www.typosansplomb.com/"
          }}>{`http://www.typosansplomb.com/`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`prototypo font `}<a parentName="p" {...{
            "href": "https://github.com/byte-foundry/john-fell.ptf"
          }}>{`https://github.com/byte-foundry/john-fell.ptf`}</a></p>
      </li>
    </ul>
    <br />
    <br />
    <br />
    <br />
    <br />
    <h4 {...{
      "id": "source-on-github"
    }}>{`Source on GitHub`}</h4>
    <p>{`Fix my mistakes `}<a parentName="p" {...{
        "href": "https://github.com/signalwerk/paramatters/blob/master/sites/example/src/pages/00--todo/index.md"
      }}>{`on GitHub`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      